import {getRequest,postRequest,getDownLoad,urlTitle} from "@/utils/request";
import { post } from "jquery";
// import { post } from "jquery";
import qs from 'qs'
window.qs = qs;

let url= urlTitle

/** 
 *    监测回路
*/
//获取所有场站id
export const stationName=() => {
  return getRequest(`${url}/power/plan/stationName`);
}
//分页获取
export const getList=(query) => {
  return getRequest(`${url}/loop/info/index`,query);
}

//新增
export const loopInfoSave=(query) => {
  return postRequest(`${url}/loop/info/save`,query);
}
//修改
export const loopInfoUpdate=(query) => {
  return postRequest(`${url}/loop/info/update`,query);
}
//删除
export const loopInfoBatchRemove=(query) => {
  return postRequest(`${url}/loop/info/delete/${query}`);
}
/**
 * 单位管理
 * */ 

//字典查询
export const searchDictCodeList=(query)=>{
  return getRequest(`${url}/asset/info/getDictNameAndDictValue`,query)
}

export const companyInfoList = query =>{
  return getRequest(`${url}/companyInfo/list`,query)
}
//省市区信息
export const companyInfoAddress = () =>{
  return getRequest(`${url}/station/info/region`,)
}

//新增单位信息
export const companyInfoAdd= query=>{
  return postRequest(`${url}/companyInfo/add`,query)
}
//修改单位信息
export const companyInfoUpdate= query=>{
  return postRequest(`${url}/companyInfo/update`,query)
}
//删除单位信息
export const companyInfoDelete= query=>{
  return postRequest(`${url}/companyInfo/delete/${query}`)
}
//单位信息层级查询
export const queryCompanyHierarchy= ()=>{
  return getRequest(`${url}/companyInfo/construction`)
}

/**菜单管理 */
// 查询列表
export const menuList= query =>{
  return getRequest(`${url}/menu/index`,query)
}
//新增
export const menuAdd = query =>{
  return postRequest(`${url}/menu/save`,query)
}
//修改
export const menuUpdate = query =>{
  return postRequest(`${url}/menu/update`,query)
}
//删除
export const menuDelete = query =>{
  return postRequest(`${url}/menu/remove/${query}`)
}
/**
 * 角色管理
 */
//查询列表
export const roleList =query =>{
  return getRequest(`${url}/role/index`,query)
}
//新增
export const roleAdd=query=>{
  return postRequest(`${url}/role/save`,query)
}
//修改
export const roleUpdate=query =>{
  return postRequest(`${url}/role/update`,query)
}
//删除
export const roleDelete=query=>{
  return postRequest(`${url}/role/remove/${query}`)
}
//修改状态
export const roleType=query=>{
  return postRequest(`${url}/role/status/${query.id}/${query.tableType}`)
}
//获取所有菜单
export const getMenuTreeList =()=>{
  return getRequest(`${url}/menu/getConstruction`)
}
//根据id获取已经绑定的菜单
export const getTableTree=query=>{
  return getRequest(`${url}/role/toAssign/${query}`)
}
//根据id获取绑定的用户
export const getUserIdList=query=>{
  return postRequest(`${url}/role/getUserByRoleId/${query}`)
}

//获取所有未绑定用户的角色
export const getUnBoundUserList=()=>{
  return postRequest(`${url}/role/getUnBoundUserList`)
}

//绑定角色
export const assignUser=query=>{
  return postRequest(`${url}/role/assignUser`,query)
}

//批量解绑角色
export const roleRemove=query=>{
  return postRequest(`${url}/role/batchRemove`,query)
}
//单条解绑角色
export const roleByUserId=query=>{
  return postRequest(`${url}/role/UnbindByUserId/${query}`)
}

/**
 * 
 * 电价类别收费管理
 * 
 * 
 */

export const  priceList=query =>{
  return getRequest(`${url}/station/info/getStationElePriceList`,query)
}
export const  addPriceList=query =>{
  return postRequest(`${url}/station/info/insetStationElePrice`,query)
}
export const  updatePriceList=query =>{
  return postRequest(`${url}/station/info/updateStationElePrice`,query)
}
export const  deletePriceList=query =>{
  return getRequest(`${url}/station/info/deleteStationElePrice?id=${query}`,)
}

/****
 * 
 * 获取用户列表
 * 
*/
export const getUserList= ()=>{
  return getRequest(`${url}/user/getUserList`)
}

//获取场站下的人员 1负责人2执行人
export const getUsersCompany=(params)=>{
  return getRequest(`${url}/companyInfo/get/company/users`,params)
}


/**
 * 空调密码管理
 */
//获取空调密码管理列表
export const getAirConditioningList= (params)=>{
  return getRequest(`${url}/station/info/getAirConditioningList`,params)
}
//新建空调密码
export const newAirConditioningPassWord=(params)=>{
  return postRequest(`${url}/station/info/addAirConditioning`,params)
}
//修改空调密码
export const editAirConditioning =(params)=>{
  return postRequest(`${url}/station/info/updAirConditioningPwd`,params)
}
//删除空调密码
export const deleteAirConditioning=(params)=>{
  return postRequest(`${url}/station/info/delAirConditioning?id=${params}`)
}