<!-- 用户管理 -->
<template>
  <div id="user">
    <!-- <div class="tree">
      <div class="tree-title">变配电站</div>
      <el-input
        placeholder="输入检索内容"
        suffix-icon="el-icon-search"
        v-model="filterText"
      ></el-input>
      <el-checkbox v-model="allSelect">全选</el-checkbox>
      <el-tree
        ref="tree"
        :default-expand-all="false"
        :show-checkbox="false"
        :data="treeData"
        :check-on-click-node="false"
        :props="defaultProps"
        node-key="id"
        @node-click="handleCheck"
      ></el-tree>
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>用户管理</span>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="requestParam">
          <el-form-item label="用户名称">
            <el-input v-model.trim="requestParam.userName" placeholder="请输入用户名称" clearable>
            </el-input>
          </el-form-item>
        </el-form>
        <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery">查询</el-button>

          <el-button v-if="isButtonPower('createButtonUser')" type="primary" icon="el-icon-plus"
            @click="createDialog">新建用户</el-button>
        </div>
      </div>

      <el-table class="table" :data="tableData">
        <el-table-column align="center" type="index" label="序号" width="75">
          <template slot-scope="scope">
            <span>{{
          (requestParam.pageNum - 1) * requestParam.pageSize +
          scope.$index +
          1
        }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="appellation" label="用户名称">
        </el-table-column>
        <el-table-column align="center" prop="userName" label="用户账号">
        </el-table-column>
        <el-table-column align="center" prop="passWord" label="用户密码">
        </el-table-column>
        <el-table-column align="center" prop="companyInfoName" label="单位">
        </el-table-column>
        <el-table-column align="center" prop="createTime" label="创建时间">
        </el-table-column>

        <el-table-column align="center" label="操作" prop="operate">
          <template slot-scope="scope">
            <div class="operate">
              <el-button type="text" @click="viewDialog(scope.row)">详情</el-button>
              <div v-if="isButtonPower('editButtonUser')">
                <span>|</span>
                <el-button type="text" @click="updateDialog(scope.row)">编辑</el-button>
              </div>
              <div v-if="isButtonPower('deleteButtonUser')">
                <span>|</span>
                <el-button type="text" @click="deleteDialog(scope.row)">删除</el-button>
              </div>

            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <button class="home-page" @click="homePage">首页</button>
        <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
          layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
          @size-change="changeSize" @current-change="changeCurrent">
        </el-pagination>
        <button class="tail-page" @click="tailPage">尾页</button>
        <div class="jump-pagination">
          <span class="text">跳至</span>
          <el-input v-model.number="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
            @change="inputValueChange"></el-input>
          <span class="text">页</span>
          <button class="confirm" @click="confirm">确定</button>
        </div>
      </div>
    </div>
    <el-dialog title="查看详情" :visible.sync="viewVisible" width="556px">
      <div class="dialog-content">
        <el-form class="dialog-form" ref="userForm" :inline="true" :model="detailsData">
          <el-form-item label="用户名称">
            <el-input v-model="detailsData.userName" :readonly="true"></el-input>
          </el-form-item>
          <!-- <el-form-item label="密码">
            <el-input v-model="detailsData.passWord" show-password></el-input>
          </el-form-item> -->
          <el-form-item label="角色">
            <el-input v-model="detailsData.roleName" :readonly="true"></el-input>
          </el-form-item>
          <el-form-item label="单位:" props="companyInfoId">
            <el-cascader :disabled="true" popper-class="cascader-popper" clearable v-model="detailsData.companyInfoId"
              :options="hierarchyData" :props="hierarchyProps" change-on-select expand-trigger="click"
              @change="selectedNode"></el-cascader>
          </el-form-item>
          <el-form-item label="用户类型" prop="operationFlage">
            <el-select :disabled="true" v-model="detailsData.operationFlage" >
              <el-option  label="管理" :value="1"></el-option>
              <el-option  label="运维" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="viewVisible = false">取 消</el-button>
        <el-button type="primary" @click="viewVisible = false">确 定</el-button> -->
      </div>
    </el-dialog>
    <el-dialog :title="requestType === 0 ? '新建用户' : '修改用户'" :visible.sync="dialogVisible" width="556px"
      @close="dialogClose('userForm')">
      <div class="dialog-content">
        <el-form class="dialog-form" id="yhFrom" ref="userForm" :inline="true" :model="userInfo" :rules="rules">
          <el-form-item label="用户账号" prop="userName">
            <el-input v-model.trim="userInfo.userName" placeholder="请输入用户账号"></el-input>
          </el-form-item>
          <el-form-item label="用户密码" prop="passWord">
            <el-input v-model.trim="userInfo.passWord" maxlength="12" show-password placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="用户名称" prop="appellation">
            <el-input v-model.trim="userInfo.appellation" placeholder="请输入用户名称"></el-input>
          </el-form-item>
          <el-form-item label="角色" prop="roleId">
            <el-select v-model="userInfo.roleId" :popper-append-to-body="false" clearable placeholder="请选择角色名称">
              <el-option v-for="item in roleData" :key="item.id" :label="item.roleName" :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item  label="单位" prop="companyInfoId">
            <el-cascader  placeholder="请选择单位"  popper-class="cascader-popper" clearable v-model="userInfo.companyInfoId"
              :options="hierarchyData" :props="hierarchyProps" change-on-select expand-trigger="click"
              @change="selectedNode" ></el-cascader>
          </el-form-item>
          <el-form-item label="用户类型" prop="operationFlage">
            <el-select v-model="userInfo.operationFlage" :popper-append-to-body="false" clearable placeholder="请选择角色类型">
              <el-option  label="管理" :value="1"></el-option>
              <el-option  label="运维" :value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogConfirm('userForm')">确 定</el-button>
      </div>
    </el-dialog>
    <el-dialog title="删除" :visible.sync="deleteVisible" width="436px">
      <div class="dialog-text">确定要删除该数据吗？</div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="deleteVisible = false">取 消</el-button>
        <el-button type="primary" @click="deleteUser">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { userApi } from "@/api/user.js";
import { queryCompanyHierarchy } from "@/api/eios.js";
export default {
  name: "",

  data() {
    return {
      tableData: [],
      requestParam: {
        pageNum: 1,
        pageSize: 10,
        userName: "",
      },
      newStationId:'',
      total: 0,
      inputValue: "",
      id: "",
      requestType: 0,
      rules: {
        roleId: [
          { required: true, message: "请选择员工卡号", trigger: "change" },
        ],
        appellation: [
          { required: true, message: "请选择用户名称", trigger: "change" },
        ],
        operationFlage: [
          { required: true, message: "请选择角色标签", trigger: "change" },
        ],
        companyInfoId: [
        { required: true, message: "请选择单位", trigger: "blur" },
        ],
        userName: [
          { required: true, message: "请输入用户名称", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9\u4e00-\u9fa5]{4,}$/,
            message: "请输入4位以上的字母大小写和数字",
            trigger: "blur",
          },
        ],
        passWord: [
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            pattern: /^[a-zA-Z0-9]{8,12}$/,
            message: "请输入8~12位之间的字母大小写和数字",
            trigger: "blur",
          },
        ],
      
      },
      deleteVisible: false,
      dialogVisible: false,
      viewVisible: false,
      hierarchyData: [],
      hierarchyProps: {
        children: "companyInfoList",
        value: "id",
        label: "companyName",
      },
      detailsData: {
        userName: "",
        passWord: "",
        roleName: "",
        companyInfoId: "",
        
      },
      userInfo: {
        userName: "",
        passWord: "",
        roleId: "",
        companyInfoId: "",
        operationFlage:'',
        appellation:'',
      },
      defaultData: {
        userName: "",
        passWord: "",
        roleId: "",
        companyInfoId: "",
        operationFlage: "",
        appellation:'',
      },
      roleData: [],
      allButtonPower: [],
    };
  },
  created() {
    this.$store.commit("increment", "Eios控制面板");
    this.$store.commit("selectChild", "用户管理");
    this.$store.commit("selectChildren", "");
  },
  mounted() {
    this.queryUserList();
    this.queryRole();
    this.queryCompanyHierarchy()
    this.allButtonPower = sessionStorage.getItem('roleInfo');
  },

  methods: {
    //按钮权限
    isButtonPower(code) {
      if (this.allButtonPower) {
        let list = this.allButtonPower.includes(code);
        return list;
      } else {
        return false;
      }
    },
    //获取父级最后一集
    selectedNode(val) {
      this.userInfo.companyInfoId = val[val.length - 1];
    },
    dialogClose(formName) {
      this.$refs[formName].resetFields();
     this.newStationId=''
      // this.userInfo.companyInfoId=''
    },
    createDialog() {
      this.requestType = 0;
      Object.assign(this.userInfo, this.defaultData);
      this.dialogVisible = true;
    },
    updateDialog(data) {
      this.requestType = 1;
      this.id = data.id;
      this.newStationId=data.newStationId
      this.queryUserDetails();
      this.dialogVisible = true;
    },
    viewDialog(data) {
      this.requestType = 2;
      this.id = data.id;
      this.queryUserDetails();
      this.viewVisible = true;
    },
    deleteDialog(item) {
      this.id = item.id;
      this.deleteVisible = true;
    },
    // 查询
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryUserList();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryUserList();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryUserList();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryUserList();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryUserList();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryUserList();
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
    },
    dialogConfirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.requestType === 0) {
            this.createUser();
          } else {
            this.updateUser();
          }
        } else {
          return false;
        }
      });
    },
    queryRole() {
      userApi.queryRole().then((res) => {
        if (res.code === 200) {
          this.roleData = res.data;
        }
      });
    },
    //获取单位层级
    queryCompanyHierarchy() {
      queryCompanyHierarchy().then((res) => {
        this.hierarchyData = res.data;
      });
    },
    queryUserDetails() {
      userApi.queryUserDetails(this.id).then((res) => {
        if (res.code === 200) {
          if (this.requestType === 1) {
            this.userInfo.userName = res.data.userName;
            this.userInfo.appellation = res.data.appellation;
            this.userInfo.passWord = res.data.passWord;
            this.userInfo.companyInfoId = res.data.companyInfoId;
           this.newStationId =  res.data.companyInfoId
            this.userInfo.operationFlage = res.data.operationFlage;
            if (res.data.role.id) {
              this.userInfo.roleId = res.data.role.id;
            }
          }
          if (this.requestType === 2) {
            this.detailsData.userName = res.data.userName;
            this.detailsData.passWord = res.data.passWord;
            this.detailsData.companyInfoId = res.data.companyInfoId;
            this.detailsData.operationFlage = res.data.operationFlage;
            this.detailsData.appellation = res.data.appellation;
            if (res.data.role.roleName) {
              this.detailsData.roleName = res.data.role.roleName;
            }
          }
        }
      });
    },
    queryUserList() {
      userApi.queryUserList(this.requestParam).then((res) => {
        if (res.code === 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    createUser() {
      userApi.createUser(this.userInfo).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryUserList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    updateUser() {
      this.userInfo.id = this.id;
      if(this.newStationId==this.userInfo.companyInfoId){
        this.newStationId=''
      }
      let obj ={
        ...this.userInfo,
        newStationId:this.newStationId
      }
      console.log(obj);
      userApi.updateUser(obj).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.dialogVisible = false;
          this.queryUserList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
    deleteUser() {
      userApi.deleteUser(this.id).then((res) => {
        if (res.code === 200) {
          this.$message({
            message: res.message,
            type: "success",
            duration: 3000,
            customClass: "messageText",
          });
          this.deleteVisible = false;
          this.queryUserList();
        } else {
          this.$message({
            message: res.message,
            type: "error",
            duration: 3000,
            customClass: "messageText",
          });
        }
      });
    },
  },
  watch: {
     // 如果只需要监听对象中的一个属性值，则可以做以下优化：使用字符串的形式监听对象属性：
     "requestParam.pageNum": {
      // 执行方法
      handler(newValue, oldValue) {
        this.inputValue = newValue;
      },
      deep: true, // 深度监听
      immediate: true, // 第一次改变就执行
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#user {
  width: 100%;
  min-height: 781px;
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.main {
  padding: 22px 0 26px 30px;
  box-sizing: border-box;
}

/* 弹窗 */
.dialog-content {
  margin: 30px 32px 0 27px;
}

.dialog-form :deep() .el-form-item {
  margin: 0;
  margin-bottom: 16px;
}

/* input */
.dialog-form :deep() .el-input .el-input__inner {
  height: 35px;
  width: 406px;
  background-color: #061b45;
  border: 1px solid #09295b;
  font-size: 12px;
  color: #aed6ff;
}

.dialog-form :deep() .el-input__inner:-webkit-autofill {
  -webkit-box-shadow: 0px 0px 0px 30px #061b45 inset;
  -webkit-text-fill-color: #aed6ff;
  border-radius: 1px;
}
</style>
<style>
.cascader-popper {
  background-color: #071734;
  border: 1px solid #09295b;
  font-size: 12px;
  color: red;
}

.cascader-popper .el-cascader-menu {
  color: #aed6ff;
}

.cascader-popper .el-cascader-node:hover {
  background: #09224b !important;
}

.cascader-popper .el-cascader-node:focus {
  background: #09224b !important;
}

.cascader-popper .popper__arrow::after {
  border-bottom-color: #071734 !important;
}

.cascader-popper {
  width: 406px;
  overflow: auto;
}

.cascader-popper::-webkit-scrollbar {
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234e;
  /* 设置滚动条背景色 */
}

.cascader-popper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
#yhFrom .el-input__inner::placeholder{
  color: #aed6ff !important;
}
</style>
