import { getRequest, postRequest, urlTitle } from "@/utils/request";
import qs from "qs";
window.qs = qs;
// 用户
export const userApi = {
  // 获取角色列表
  queryRole: (params) => {
    return getRequest(`${urlTitle}/user/getAllRoles`, params);
  },
  queryUserList: (params) => {
    return getRequest(`${urlTitle}/user/index`, params);
  },
  // 请求用户 1---运维, 2---管理
  queryUser: (params) => {
    return getRequest(`${urlTitle}/user/getUsers`, params);
  },
  // 用户列表
  queryUserDetails: (userId) => {
    return getRequest(`${urlTitle}/user/get/${userId}`);
  },
  // 创建用户
  createUser: (params) => {
    return postRequest(`${urlTitle}/user/save`, params);
  },
  // 修改用户
  updateUser: (params) => {
    return postRequest(`${urlTitle}/user/update`, params);
  },
  // 删除用户
  deleteUser: (id) => {
    return postRequest(`${urlTitle}/user/remove/${id}`);
  },
};
